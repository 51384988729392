import { assertUnreachable } from "./assertUnreachable";
import { postAttributes } from "~/types";

/**
 * It takes a post and returns the router path to that post
 * @param {Post} post - Post
 * @returns The path to the post.
 */
export function getPostPath(post: postAttributes) {
  switch (post.type) {
    case "Webinar":
      return `/resources/webinars/${post.slug}/`;
    case "Event":
      return `/events/${post.slug}/`;
    case "Presentation":
      return `/resources/presentations/${post.slug}/`;
    case "Factsheet":
      return `/resources/factsheets/${post.slug}/`;
    case "Newsletter":
      return `/resources/newsletters/${post.slug}/`;
    case "Use Case":
      return `/scenarios/${post.slug}/`;
    case "Product Spotlight":
      return `/product-spotlights/${post.slug}/`;
    case "Press Release":
      return `/articles/${post.slug}/`;
    case "Article":
      return `/articles/${post.slug}/`;
    case "Blog":
      return `/articles/${post.slug}/`;
    default:
      return assertUnreachable(post.type as never);
  }
}
